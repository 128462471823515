import React, { useEffect, useState } from "react"
import "./FormSteps.less"
import { Button, Steps, Typography, Form } from "antd"
import Profile from "../Forms/Profile"
import Experience from "../Forms/Experience"
import Passion from "../Forms/Passion"
import StepCheck from "../../../assets/mentor/stepCheck.svg"
import StepCompleteCheck from "../../../assets/mentor/stepCompleteCheck.svg"
import EmptyStepCheck from "../../../assets/mentor/emptyStepCheck.svg"
import AppliedSuccess from "../../../assets/mentor/appliedSuccess.svg"
import AppliedFailure from "../../../assets/mentor/appliedFailure.svg"
import { Link } from "gatsby"

const { Title, Text } = Typography
const { Step } = Steps

const FormSteps = () => {
  const [form] = Form.useForm()
  const [current, setCurrent] = useState(0)
  const [orgList, setOrgList] = useState([])
  const [interestList, setInterestList] = useState([])
  const [userExists, setUserExists] = useState(false)
  const [profilePhoto, setProfilePhoto] = useState([])
  const [finalResponse, setFinalResponse] = useState({
    success: true,
    title: "Applied successfully!",
    text: "You have successfully applied to Mentro. Wait for some days for further process",
  })
  const [formData, setFormData] = useState({
    uid: "",
    name: "",
    email: "",
    phoneNo: "",
    bio: "",
    address: "",
    quote: "Mentorship Matters...",
    org: "",
    profileUrl: "",
    linkedInUrl: "",
    twitterUrl: "",
    orgImageUrl: "",
    role: "",
    interests: [],
    referral: "",
  })

  const next = () => {
    setCurrent(current + 1)
  }

  const prev = () => {
    setCurrent(current - 1)
  }

  const getData = async () => {
    const response = await fetch(
      `${process.env.GATSBY_BE_URL}/getMentorFormData/`
    )
    const data = await response.json()
    setOrgList(data.orgs)
    setInterestList(data.interests)
  }

  useEffect(() => {
    getData()
  }, [])

  const steps = [
    {
      title: "Profile",
      content: (
        <Profile
          next={next}
          form={form}
          formData={formData}
          setFormData={setFormData}
          profilePhoto={profilePhoto}
          setProfilePhoto={setProfilePhoto}
          setUserExists={setUserExists}
        />
      ),
    },
    {
      title: "Experience",
      content: (
        <Experience
          form={form}
          prev={prev}
          next={next}
          orgList={orgList}
          formData={formData}
          setFormData={setFormData}
        />
      ),
    },
    {
      title: "Passion",
      content: (
        <Passion
          form={form}
          prev={prev}
          next={next}
          interestList={interestList}
          formData={formData}
          setFormData={setFormData}
          profilePhoto={profilePhoto}
          userExists={userExists}
          setFinalResponse={setFinalResponse}
        />
      ),
    },
  ]

  return (
    <div className="stepsParent">
      <div className="stepsDiv">
        {current < 3 && (
          <>
            <div className="stepTitleDiv">
              <Title className="stepTitle profileTitle">Profile</Title>
              <Title className="stepTitle experienceTitle">Experience</Title>
              <Title className="stepTitle passionTitle">Passion</Title>
            </div>
            <Steps current={current} className="stepsAntd">
              {steps.map((item, index) => (
                <Step
                  icon={
                    current > index ? (
                      <img
                        src={StepCompleteCheck}
                        alt="step complete check mark"
                        className="stepCompleteCheck"
                      />
                    ) : current === index ? (
                      <img
                        src={StepCheck}
                        alt="step check mark"
                        className="stepCheck"
                      />
                    ) : (
                      <img
                        src={EmptyStepCheck}
                        alt="empty step check mark"
                        className="emptyStepCheck"
                      />
                    )
                  }
                  // subTitle={item.title}
                  key={item.title}
                  className="stepAntd"
                />
              ))}
            </Steps>
            <div className="steps-content">{steps[current].content}</div>
          </>
        )}

        {current > 2 && (
          <div className="successDiv">
            <div className="top">
              <img
                src={finalResponse.success ? AppliedSuccess : AppliedFailure}
                alt="applied successfully or failure illustration"
              />
              <Title className="title">{finalResponse.title}</Title>
              <Text className="text">{finalResponse.text}</Text>
            </div>
            <Link to="/mentor">
              <Button type="text" className="continueBtn">
                Back to website
              </Button>
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}

export default FormSteps
