import React, { useState } from "react"
import { Form, Button, Typography, Tag, Input } from "antd"
import "./Passion.less"
import BackIcon from "../../../../assets/mentor/backIcon.svg"
import { app } from "../../../../../firebaseConfig"

const { Title, Text } = Typography
const { CheckableTag } = Tag

const Passion = ({
  next,
  prev,
  interestList,
  formData,
  setFormData,
  userExists,
  profilePhoto,
  setFinalResponse,
  form,
}) => {
  const [selectedTags, setSelectedTags] = useState<any>(
    form.getFieldValue("tags")?.split(",") || []
  )
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const onFinish = async values => {
    if (selectedTags.length > 0) {
      setIsLoading(true)

      let newUid
      if (!formData.uid) {
        const response: any = await app
          .auth()
          .createUserWithEmailAndPassword(formData.email, "password")

        newUid = await response.user.uid
        setFormData({ ...formData, uid: newUid })
      } else {
        newUid = formData.uid
      }

      const uploadTask = app
        .storage()
        .ref(`users/${newUid}/profile.jpg`)
        .put(profilePhoto[0].originFileObj)

      uploadTask.on(
        "state_changed",
        snapshot => {
          return snapshot
        },
        err => console.error(err),
        () => {
          uploadTask.snapshot.ref
            .getDownloadURL()
            .then(urlData => {
              if (urlData) {
                setFormData({
                  ...formData,
                  profileUrl: urlData,
                  interests: selectedTags,
                  referral: values.referral,
                })

                fetch(
                  `${process.env.GATSBY_BE_URL}/mentorRegisterForm?userExists=${userExists}`,
                  {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      ...formData,
                      interests: selectedTags,
                      referral: values.referral,
                      profileUrl: urlData,
                      uid: newUid,
                    }),
                  }
                )
                  .then(response => response.json())
                  .then(data => {
                    if (data.success) {
                      setIsLoading(false)
                      setFinalResponse({
                        success: true,
                        title: "Applied successfully!",
                        text: "You have successfully applied to Mentro. Wait for some days for further process",
                      })
                      next()
                    }
                  })
                  .catch(err => {
                    setFinalResponse({
                      success: false,
                      title: "Opps! Somethings wrong.",
                      text: `There has been an issue on our server. Try again after some time.`,
                    })
                    setIsLoading(false)
                    console.error(err)
                  })
              }
            })
            .catch(err => {
              setFinalResponse({
                success: false,
                title: "Opps! Somethings wrong.",
                text: `There has been an issue on our server. Try again after some time.`,
              })
              setIsLoading(false)
              console.error(err)
            })
        }
      )
    }
  }

  const onFinishFailed = errorInfo => {
    console.error("Failed:", errorInfo)
  }

  const handleChange = (tag, checked) => {
    const nextSelectedTags = checked
      ? [...selectedTags, tag.value]
      : selectedTags.filter(t => t !== tag.value)
    setSelectedTags(nextSelectedTags)
    form.setFieldsValue({ tags: nextSelectedTags.join(",") })
  }

  return (
    <div className="passionParent">
      <Title className="title">Tell us about your passion</Title>
      <div className="passionform">
        <Form
          name="basic"
          form={form}
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <div className="tags">
            {interestList.map(tag => (
              <CheckableTag
                className="checkTag"
                key={tag.value}
                checked={selectedTags.indexOf(tag.value) > -1}
                onChange={checked => handleChange(tag, checked)}
              >
                {tag.label}
              </CheckableTag>
            ))}
          </div>
          <Form.Item
            label={<Text className="referralText">REFERRAL CODE (If Any)</Text>}
            name="referral"
          >
            <Input placeholder="Enter your Referral Code" />
          </Form.Item>
          <div className="buttonsDiv">
            <Form.Item>
              <Button type="text" className="backBtn" onClick={prev}>
                Back
              </Button>
              <Button type="text" className="backIconBtn" onClick={prev}>
                <img src={BackIcon} alt="back icon" />
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                type="text"
                htmlType="submit"
                className="continueBtn"
                loading={isLoading}
              >
                Continue
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default Passion
