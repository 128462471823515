import React, { useState } from "react"
import { AutoComplete, Form, Input, Button, Typography } from "antd"
import "./Experience.less"
import BackIcon from "../../../../assets/mentor/backIcon.svg"
import ErrorMessage from "../../../../assets/mentor/errorMessage.svg"

const { Text, Title } = Typography

const Experience = ({ next, prev, orgList, formData, setFormData, form }) => {
  const [options, setOptions] = useState<any[]>([
    ...orgList.map(el => {
      return {
        value: el.name,
      }
    }),
  ])
  const onFinish = values => {
    const { company, linkedInUrl, twitterUrl, role } = values

    const org = orgList.find(org => org.name === company)

    setFormData({
      ...formData,
      org: company,
      orgImageUrl: org ? org.imageUrl : "",
      linkedInUrl,
      twitterUrl,
      role,
    })
    next()
  }

  const onFinishFailed = errorInfo => {
    //
  }
  const searchBox = el => {
    const data = [
      ...orgList.map(el => {
        return {
          value: el.name,
        }
      }),
    ]
    const newOps = data.filter(
      optionVal => optionVal.value.toLowerCase().indexOf(el.toLowerCase()) > -1
    )
    if (newOps.length === 0) {
      setOptions([{ value: el }])
      return
    }
    if (el === "") {
      setOptions([...data])
      return
    }

    setOptions(newOps)
  }

  return (
    <div className="experienceParent">
      <Title className="title">Tell us about your experience</Title>
      <div className="experienceform">
        <Form
          form={form}
          name="basic"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label={<Text className="text">COMPANY NAME</Text>}
            name="company"
            rules={[
              {
                required: true,
                message: (
                  <Text className="errorMessage">
                    <img src={ErrorMessage} alt="errorMessage" />
                    Please enter your Company
                  </Text>
                ),
              },
            ]}
          >
            <AutoComplete options={options} onSearch={searchBox} allowClear>
              <Input type="text" id="select-data" />
            </AutoComplete>
          </Form.Item>
          <Form.Item
            label={<Text className="text">PROFESSIONAL ROLE</Text>}
            name="role"
            rules={[
              {
                required: true,
                message: (
                  <Text className="errorMessage">
                    <img src={ErrorMessage} alt="errorMessage" />
                    Please enter your Role
                  </Text>
                ),
              },
            ]}
          >
            <Input placeholder="Enter your Role" />
          </Form.Item>
          <Form.Item
            label={<Text className="text">LINKEDIN PROFILE</Text>}
            name="linkedInUrl"
            rules={[
              {
                required: true,
                message: (
                  <Text className="errorMessage">
                    <img src={ErrorMessage} alt="errorMessage" />
                    Please enter your Linkedin Profile
                  </Text>
                ),
              },
              {
                pattern: /https:\/\/(www\.)?linkedin\.com\/in\/[A-z0-9_-]+\/?/,
                message: (
                  <Text className="errorMessage">
                    <img src={ErrorMessage} alt="errorMessage" />
                    Please enter a valid LinkedIn url
                  </Text>
                ),
              },
            ]}
          >
            <Input placeholder="https://www.linkedin.com/in/..." />
          </Form.Item>
          <Form.Item
            label={<Text className="text">TWITTER PROFILE</Text>}
            name="twitterUrl"
            rules={[
              {
                pattern:
                  /http(?:s)?:\/\/(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)/,
                message: (
                  <Text className="errorMessage">
                    <img src={ErrorMessage} alt="errorMessage" />
                    Please enter a valid Twitter url
                  </Text>
                ),
              },
            ]}
          >
            <Input placeholder="https://www.twitter.com/..." />
          </Form.Item>
          <div className="buttonsDiv">
            <Form.Item>
              <Button type="text" className="backBtn" onClick={prev}>
                Back
              </Button>
              <Button
                type="text"
                className="backIconBtn"
                onClick={prev}
                htmlType="submit"
              >
                <img src={BackIcon} alt="back icon" />
              </Button>
            </Form.Item>
            <Form.Item>
              <Button type="text" htmlType="submit" className="continueBtn">
                Continue
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default Experience
