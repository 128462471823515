import React, { useEffect, useState } from "react"
import { Form, Input, Button, Typography, Upload, Row, Col } from "antd"
import ImgCrop from "antd-img-crop"
import "./Profile.less"
import DefaultUser from "../../../../assets/mentor/defaultUser.svg"
import ErrorMessage from "../../../../assets/mentor/errorMessage.svg"
import "antd/es/modal/style"
import "antd/es/slider/style"

const { Text, Title } = Typography
const { TextArea } = Input

const Profile = ({
  next,
  formData,
  setFormData,
  setUserExists,
  setProfilePhoto,
  profilePhoto,
  form,
}) => {
  const [fileList, setFileList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isEmailVerified, setIsEmailVerified] = useState({
    status: "hold",
    message: "",
  })
  const [isProfilePhoto, setIsProfilePhoto] = useState({
    status: "hold",
    message: "",
  })

  const onFinish = async values => {
    const { name, email, bio, address, quote, phoneNo } = values
    if (!email) {
      return setIsEmailVerified({
        status: "error",
        message: "Enter your Email",
      })
    }
    if (fileList.length < 1) {
      return setIsProfilePhoto({
        status: "error",
        message: "Please Upload your image",
      })
    }
    const emailRE =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (!emailRE.test(email)) {
      return setIsEmailVerified({
        status: "error",
        message: "Enter a valid Email",
      })
    }
    setIsLoading(true)
    const response = await fetch(
      `${process.env.GATSBY_BE_URL}/checkMentorFormEmail?email=${values.email}`
    )
    const data = await response.json()
    if (data.success) {
      setIsEmailVerified({ status: "success", message: "" })
      setUserExists(data.userExists)
      let uid
      if (data.userExists) {
        uid = data.uid
      } else {
        uid = ""
      }
      setFormData({
        ...formData,
        name,
        email,
        bio,
        address,
        phoneNo,
        quote,
        uid,
        profileUrl: "",
      })
      setProfilePhoto(fileList)
      setIsLoading(false)
      next()
    } else {
      setIsLoading(false)
      setIsEmailVerified({ status: "error", message: data.message })
    }
  }

  const onFinishFailed = errorInfo => {
    //
  }

  const onUploadFile = ({ fileList: newFileList }) => {
    const isLt2M = newFileList[0].size / 1024 / 1024 < 2
    if (!isLt2M) {
      setIsProfilePhoto({
        status: "error",
        message: "Image size is greater than 2MB",
      })
      return console.error("Image must smaller than 2MB!")
    }

    setFileList(newFileList)
    setIsProfilePhoto({ status: "success", message: "" })
  }
  useEffect(() => {
    form.setFields([
      { name: "name", value: formData.name },
      { name: "email", value: formData.email },
      { name: "quote", value: formData.quote },
      { name: "bio", value: formData.bio },
      { name: "address", value: formData.address },
    ])
    setFileList(profilePhoto)
  }, [])
  return (
    <div className="profileParent">
      <Title className="title">Tell us about yourself</Title>
      <div className="profileform">
        <Form
          form={form}
          name="basic"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            validateStatus={isProfilePhoto.status === "error" ? "error" : ""}
            help={
              isProfilePhoto.message ? (
                <Text className="errorMessage">
                  <img src={ErrorMessage} alt="errorMessage" />
                  {isProfilePhoto.message}
                </Text>
              ) : null
            }
            label={<Text className="text">PROFILE PICTURE</Text>}
            name="profilePicture"
            className="uploadPicForm"
          >
            {
              <img
                src={DefaultUser}
                alt="defaultUser"
                className="defaultUser"
                style={
                  fileList.length === 1 ? { visibility: "hidden" } : undefined
                }
              />
            }
            <ImgCrop grid rotate>
              <Upload
                onChange={onUploadFile}
                listType="picture-card"
                fileList={fileList}
                accept="image/png, image/jpeg"
                className="upload-list-inline"
                maxCount={1}
              >
                <div className="btnDiv">
                  <Button type="text" className="uploadBtn">
                    Upload image
                  </Button>
                </div>
                <Text className="text">
                  You can upload .jpeg or .png image files. Max size of 2MB.
                </Text>
              </Upload>
            </ImgCrop>
            <Button
              type="text"
              className="removeBtn"
              onClick={() => setFileList([])}
            >
              Remove
            </Button>
          </Form.Item>
          <Row className="nameDiv">
            <Col>
              <Form.Item
                label={<Text className="text">NAME</Text>}
                name="name"
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: (
                      <Text className="errorMessage">
                        <img src={ErrorMessage} alt="errorMessage" />
                        Please enter your Name
                      </Text>
                    ),
                  },
                ]}
              >
                <Input placeholder="Enter your Name" />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                validateStatus={
                  isEmailVerified.status === "error" ? "error" : ""
                }
                help={
                  isEmailVerified.message ? (
                    <Text className="errorMessage">
                      <img src={ErrorMessage} alt="errorMessage" />
                      {isEmailVerified.message}
                    </Text>
                  ) : null
                }
                label={<Text className="text">EMAIL</Text>}
                name="email"
                wrapperCol={{ span: 24 }}
              >
                <Input placeholder="Enter your Email" />
              </Form.Item>
            </Col>
          </Row>
          <Row className="quoteDiv">
            <Col>
              <Form.Item
                label={<Text className="text">QUOTE</Text>}
                name="quote"
                rules={[
                  {
                    required: true,
                    message: (
                      <Text className="errorMessage">
                        <img src={ErrorMessage} alt="errorMessage" />
                        Please enter a Quote
                      </Text>
                    ),
                  },
                ]}
              >
                <Input placeholder="Enter a Quote" />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                label={<Text className="text">PHONE</Text>}
                name="phoneNo"
                rules={[
                  {
                    required: true,
                    message: (
                      <Text className="errorMessage">
                        <img src={ErrorMessage} alt="errorMessage" />
                        Please enter your Phone No
                      </Text>
                    ),
                  },
                  {
                    pattern:
                      /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                    message: (
                      <Text className="errorMessage">
                        <img src={ErrorMessage} alt="errorMessage" />
                        Please enter a valid Phone No
                      </Text>
                    ),
                  },
                ]}
              >
                <Input placeholder="Enter a Phone No" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label={<Text className="text">BIO</Text>}
            name="bio"
            rules={[
              {
                required: true,
                message: (
                  <Text className="errorMessage">
                    <img src={ErrorMessage} alt="errorMessage" />
                    Please enter a Bio
                  </Text>
                ),
              },
            ]}
          >
            <TextArea rows={4} placeholder="Enter your Bio" />
          </Form.Item>
          <Form.Item
            label={<Text className="text">ADDRESS</Text>}
            name="address"
          >
            <TextArea rows={4} placeholder="Enter your Address" />
          </Form.Item>
          <div className="continueDiv">
            <Form.Item>
              <Button
                type="text"
                htmlType="submit"
                className="continueBtn"
                loading={isLoading}
              >
                Continue
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default Profile
