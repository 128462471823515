import React, { useEffect } from "react"
import Container from "../../components/MentorApply/Container"
import FormSteps from "../../components/MentorApply/FormSteps"
import "../../theme/mentorApply.less"

const MentorApplyPage = () => {
  // useEffect(() => {
  //   //TAWK.to
  //   var Tawk_API = Tawk_API || {}

  //   var s1: any = document.createElement("script")
  //   var s0: any = document.getElementsByTagName("script")[0]
  //   s1.async = true
  //   s1.src = "https://embed.tawk.to/622f1fada34c2456412af169/1fu417g0k"
  //   s1.charset = "UTF-8"
  //   s1.setAttribute("crossorigin", "*")
  //   s0.parentNode.insertBefore(s1, s0)
  // }, [])

  return (
    <Container>
      <FormSteps />
    </Container>
  )
}

export default MentorApplyPage
